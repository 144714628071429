import { getDocs, collection, query, where, documentId } from 'firebase/firestore'
import { db } from '../system/firebase/index'
import { setUserVehicle } from '../system/redux/reducers/vehicle';

export const setVehicle = (dispatch, vid) => {
    const q = query(collection(db, 'cars'), where(documentId(), '==', vid ))
    getDocs(q).then((docs) => {
        if (docs.docs.length) {
            docs.forEach((doc) => {
                const vehicleData = doc.data()
                vehicleData.vid = doc.id
                const dataString = JSON.stringify(vehicleData)
                dispatch(setUserVehicle(dataString))
            })
        } else {
            // console.log('No vehicle found')
        }
    })
}
