import React from 'react'
// import { Box, Heading, Text, Image } from 'rebass/styled-components'
import { Box, Heading, Image } from 'rebass/styled-components'
import { connect } from 'react-redux'
import Layout from '../templates/Main'

const Error404 = ({ location }) => {
    return (


        <Layout location={location}>
            <Box
                mt={['lg', 'lg', 'lg', 'lg', 'lg', 'lg', 'lg']}
            >
                <Box>
                    <Image
                        alt=""
                        decoding="async"
                        loading="lazy"
                        src='https://res.cloudinary.com/kidston/image/upload/website/no-results.jpg' />
                </Box>
                <Box
                    pb={['md', 'md', 'md', 'lg', 'lg', 'lg', 'lg']}
                    pl={['md', 'md', 'md', 'lg', 'lg', 'lg', 'lg']}
                    pr={['md', 'md', 'md', 'lg', 'lg', 'lg', 'lg']}
                    variant="container"
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Box
                        bg="white"
                        pb={['md', 'md', 'md', 'lg', 'lg', 'lg', 'lg']}
                        pl={['md', 'md', 'md', 'lg', 'lg', 'lg', 'lg']}
                        pr={['md', 'md', 'md', 'lg', 'lg', 'lg', 'lg']}
                        pt={['md', 'md', 'md', 'md', 'md', 'md', 'md']}
                        sx={{
                            marginTop: '-90px',
                        }}
                    >
                        <Heading
                            as="h2"
                            pb="md"
                            variant="h2"
                            sx={{
                                textTransform: 'uppercase',
                            }}>Sorry, we couldn&#8217;t find that page</Heading>
                        {/* <Text as="p">text here</Text> */}
                    </Box>
                </Box>
            </Box>
        </Layout>

    );
}


export default connect()(Error404)
